// --------------------------------------------------
//   Import Required Scripts
// --------------------------------------------------

import {
  DB_NAME,
  OS_NAME,
} from "./settings.js";



// --------------------------------------------------
//   Global Variables
// --------------------------------------------------


// IndexedDB 
let localDB;



// --------------------------------------------------
//   Database Initialization
// --------------------------------------------------


/**
 * Open and possibly initialize the database object.
 */
function initializeDB() {
  return new Promise((resolve, reject) => {

    // Open our database; it is created if it doesn't already exist
    // (see the upgradeneeded handler below)
    const openRequest = window.indexedDB.open(DB_NAME, 1);

    // Database didn't open successfully
    openRequest.addEventListener("error", (err) => {
      console.error("Database failed to open");
      reject(err);
    });

    // Database did open successfully
    // Return the opened database object
    openRequest.addEventListener("success", () => {
      resolve(openRequest.result);
    });

    // Set up the database tables if this has not already been done
    openRequest.addEventListener("upgradeneeded", (e) => {

      // // Grab a reference to the opened database
      const dbObject = e.target.result;

      // Create an objectStore in our database to store notes and an auto-incrementing key
      // An objectStore is similar to a 'table' in a relational database
      const objectStore = dbObject.createObjectStore(OS_NAME, {
        keyPath: "id",
        autoIncrement: true,
      });

      // Define what data items the objectStore will contain
      objectStore.createIndex("title", "title", { unique: false });
      objectStore.createIndex("blob",  "blob",  { unique: false });

      // Print setup complete message
      console.log("Database setup complete");
    });
  });
}


/**
 * Initialize the database, and assign the opened object to the global `db` variable.
 */
async function ensureDatabaseExists() {
  localDB = await initializeDB();
}



// --------------------------------------------------
//   Database Storage
// --------------------------------------------------


/**
 * Retrieve all stored recordings.
 */
export function getStoredRecordings() {
  return new Promise(async (resolve, reject) => {

    // Make sure database object has been initialized
    try {
      await ensureDatabaseExists();
    }

    // If database cannot be initialized, reject here
    catch (e) {
      console.error('Local database could not be opened.');
      reject(e);
    }

    // Array to store recording objects
    let storedRecordings = []

    // Open our object store and then get a cursor - which iterates through all the
    // different data items in the store
    const objectStore = localDB.transaction(OS_NAME).objectStore(OS_NAME);
    objectStore.openCursor().addEventListener("success", (e) => {

      // Get a reference to the cursor
      const cursor = e.target.result;

      // If there is still another data item,
      // add it to the list and iterate the cursor to the next item
      if (cursor) {
        storedRecordings.push({ recordingID: cursor.primaryKey, ...cursor.value });
        cursor.continue();
      }
      
      // Once the cursor finishes, all recordings have been retrieved
      else {
        resolve(storedRecordings);
      }
    });
  });
}


/**
 * Store a recording in the database.
 */
export function storeRecording(title, blob) {
  return new Promise(async (resolve, reject) => {

    // Make sure database object has been initialized
    try {
      await ensureDatabaseExists();
    }

    // If database cannot be initialized, reject here
    catch (e) {
      console.error('Local database could not be opened.');
      reject(e);
    }

    // Initialize variable to store ID of recording in database
    let recordingID;

    // Open a read/write db transaction, ready for adding the data
    const transaction = localDB.transaction([OS_NAME], "readwrite");

    // Call an object store that's already been added to the database
    const objectStore = transaction.objectStore( OS_NAME );

    // Make a request to add the video to the object store as a formatted db object
    const addRequest = objectStore.add({ title, blob });

    // When the add request completes, the result stores the ID of the new recording
    // in the database
    addRequest.addEventListener("success", (e) => {
      recordingID = e.target.result;
    });

    // When the entire transaction completes, we can propagate the recording ID
    transaction.addEventListener("complete", () => {
      console.log("Video backed up successfully");
      resolve( recordingID );
    });

    transaction.addEventListener("error", (err) => {
      console.log("Transaction not opened due to error");
      reject(err);
    });
  });
}


/**
 * Remove a recording from the database.
 */
export function clearRecording(recordingID) {
  return new Promise(async (resolve, reject) => {

    // Make sure database object has been initialized
    try {
      await ensureDatabaseExists();
    }

    // If database cannot be initialized, reject here
    catch (e) {
      console.error('Local database could not be opened.');
      reject(e);
    }

    // Open a database transaction and delete the recording, using its database ID
    const transaction   = localDB.transaction([OS_NAME], "readwrite");
    const objectStore   = transaction.objectStore(OS_NAME);
    const deleteRequest = objectStore.delete(recordingID);

    // report that the data item has been deleted
    transaction.addEventListener("complete", () => {
      console.log("Deleted recording: ", recordingID)
      resolve();
    });
  })
}


/**
 * Completely clear the local IndexedDB storage.
 */
export function deleteAllRecordings() {
  return new Promise(async (resolve, reject) => {

    // Create the delete request
    const DBDeleteRequest = window.indexedDB.deleteDatabase( DB_NAME );

    DBDeleteRequest.onerror = (event) => {
      reject(event)
    };

    DBDeleteRequest.onsuccess = (event) => {
      console.log("Database deleted successfully");
      resolve(event.result);  // should be undefined
    };
  })
}
