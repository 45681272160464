import React, {useState, useEffect} from "react";
import { 
    Box, 
    Card, 
    CardMedia,
    CardContent,
    Typography,
    List,
    ListItem,
    Link,
    CircularProgress
 } from "@mui/material";

//   API
 import { fetchVideos } from '../api/course-service';
 import { fetchUser } from '../api/user-service';

 import Navigation from "./Navigation";
 import Error from "./Error";


const StudentDashboard = () => {

    const [videos, setVideos] = useState([]);
    const [user, setUser] = useState({});
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
         // Get videos
         fetchVideos().then(data => {
            setVideos(data);
            setIsLoading(false);
        },
        error => {
            setIsLoading(false);
            setError(error.message)
        });

        // Get user
        fetchUser().then(data => {
            setUser(data)
            setIsLoading(false);
        },
        error => {
            setIsLoading(false);
            setError(error.message)
        });
    }, []);

    if (error) {
        return <Error message={error} /> 
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                <CircularProgress></CircularProgress>
            </Box>
        )
    }

    return (
        <div>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography sx={{
                    fontSize: {
                        xs: '1.25rem',
                        sm: '1.5rem',
                        md: '2rem',
                    }
                    }}>Hello, {user.username}!</Typography>
                <Navigation />
            </Box>
            <Typography variant="h5" sx={{marginTop: '2rem'}}>Videos:</Typography>
            {videos.length === 0 && <p>No videos found</p>}
            <List sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                },
                flexWrap: {
                    xs: 'nowrap',
                    sm: 'nowrap',
                    md: 'wrap',
                },
                }}>
                {videos.map(video => (
                    <ListItem
                    key={video.id}
                    sx={{
                        width: 
                        {
                            xs: 500,
                            sm: 500,
                            md: 350,
                        },
                        minWidth: 350,
                    }}
                    >
                        <Link
                            href={`/video/${video.id}`}
                        >
                            <Card>
                                <CardMedia>
                                    <Box component="video"
                                    src={`${video.url}#t=25`}
                                    alt="video"
                                    width="100%"
                                    />
                                </CardMedia>
                                <CardContent sx={{
                                    height: {
                                        xs: 10,
                                        sm: 20,
                                        md: 30

                                    }
                                }}>
                                    <Typography variant="h6">Video {video.name.toUpperCase()}</Typography>
                                </CardContent>
                            </Card>
                        </Link>
                        
                    </ListItem>
                ))}
            </List>
    </div>
    );
}

export default StudentDashboard;