import { 
    Box, 
    CircularProgress,
 } from "@mui/material";
import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { fetchUser, userRoles } from "../api/user-service";
import Error from "./Error";


const Dashboard = () => {
    const [user, setUser] = useState({});
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        // Get user
        fetchUser().then(data => {
            setUser(data)
            setIsLoading(false);
            if (user.role === userRoles.ADMIN) {
                navigate('/admin');
            } else {
                navigate('/student');
            }
        },
        error => {
            setIsLoading(false);
            setError(error.message)
        });

    }, []);

    if (error) {
        return <Error message={error} />
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                <CircularProgress></CircularProgress>
            </Box>
        )
    }


}

export default Dashboard;