// --------------------------------------------------
//   Imports
// --------------------------------------------------

// React
import { useState, useEffect, useRef } from 'react';

// Material UI
import {
  Box,
  Dialog,
  DialogTitle,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// Custom Components
import DeviceList from '../mediaDevices/DeviceList';
import VideoCanvas from '../mediaDevices/VideoCanvas';
import { useTrackedError } from '../../scripts/errorHandling';



// --------------------------------------------------
//   Component
// --------------------------------------------------

export default function SelectDeviceDialog({ onClose, open, videoRef, videoDevice, setVideoDevice, audioDevice, setAudioDevice }) {

  // Track available devices
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);

  // Track error getting devices
  const [errorState, setErrorState] = useTrackedError();

  // Lookup and separate out media devices
  useEffect(() => {
    getDevices();
  }, [open])

  function getDevices() {
    try {
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          setVideoDevices(devices.filter(({kind}) => kind === "videoinput"))
          setAudioDevices(devices.filter(({kind}) => kind === "audioinput"))
        })
        .catch((err) => {
          setErrorState.set('CANNOT_SHOW_DEVICES', err)
        })
    }
    catch (err) {
      setErrorState.set('CANNOT_SHOW_DEVICES', err)
    }
  }


  // Component
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true} maxWidth="lg"
      PaperProps={{sx: {"overflow-y": "hidden", height: "95%", maxHeight: "95%"}}}
    >

      <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" component="h5">
          Webcam Settings
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box flex={1} maxHeight="60%">
        <VideoCanvas videoRef={ videoRef } />
      </Box>

      <Stack direction="row" maxWidth="100%" marginTop={2} position="relative" minHeight="0">

        <Stack direction="column" flex={1} height="100%">
          <Typography component="h4" variant="h6" textAlign="center">
            Video
          </Typography>
          <Box flex={1} sx={{
            "overflow-y": "scroll",
            "overflow-x": "hidden",
          }}>
            <DeviceList
              devices  = { videoDevices }
              onSelect = { setVideoDevice }
              selected = { videoDevice }
              showIDs  = { false }
              listStyles = {{
                pt: 0,
                pb: 0,
                minHeight: "100%",
              }}
            />
          </Box>
        </Stack>

        <Stack direction="column" flex={1} height="100%">
          <Typography component="h4" variant="h6" textAlign="center">
            Audio
          </Typography>
          <Box flex={1} sx={{
            "overflow-y": "scroll",
            "overflow-x": "hidden",
          }}>
            <DeviceList
              devices  = { audioDevices }
              onSelect = { setAudioDevice }
              selected = { audioDevice }
              showIDs  = { false }
              listStyles = {{
                pt: 0,
                pb: 0,
                minHeight: "100%",
              }}
            />
          </Box>
        </Stack>

      </Stack>
    </Dialog>
  )
}
