// --------------------------------------------------
//   Imports
// --------------------------------------------------

// React
import { useState, useEffect, useRef } from 'react';

// Material UI
import {
  Box,
} from "@mui/material";



// --------------------------------------------------
//   Component
// --------------------------------------------------

export default function VideoCanvas({ videoRef, defaultRatio = 4 / 3 }) {

  // Create reference for canvas element
  const canvasRef = useRef(null);

  // Set the initial canvas size based on the container and the default ratio
  useEffect(() => {
    const $canvas  = canvasRef.current;
    const width    = $canvas.parentElement.getBoundingClientRect().width;
    $canvas.width  = width;
    $canvas.height = width / defaultRatio;
  }, [])

  // Track the video ref
  useEffect(() => {
    if (videoRef.current) startVideo();
  }, [videoRef.current])


  async function startVideo() {
    console.log("Starting VideoCanvas: ", canvasRef, videoRef)

    // The video element to display from
    const $webcam = videoRef.current;

    // The canvas element to display on
    const $canvas = canvasRef.current;
    const ctx = $canvas.getContext('2d');

    /**
     * Callback that begins rendering video content to the canvas element.
     * Sets up an animation loop.
     */
    function renderVideo(event) {

      // Use the video aspect ratio to compute the canvas height
      const aspectRatio = $webcam.videoWidth / $webcam.videoHeight;

      // Fit the canvas into its parent element
      let width  = $canvas.parentElement.getBoundingClientRect().width;
      let height = width / aspectRatio;

      // Set the canvas dimensions to the computed values
      $canvas.width  = width;
      $canvas.height = height;

      // Update width and height on window resize to keep canvas size correct
      window.addEventListener('resize', () => {

        // Fit the canvas into its parent element
        width = $canvas.parentElement.getBoundingClientRect().width;
        height = width / aspectRatio;

        // Set the canvas dimensions to the computed values
        $canvas.width  = width;
        $canvas.height = height;
      })

      // Set up the animation loop
      const draw = () => {
        ctx.drawImage($webcam, 0, 0, width, height);
        requestAnimationFrame(draw);
      }
      requestAnimationFrame(draw);
    }

    // If video element has data, start rendering
    if ($webcam.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) {
      renderVideo(null)
    }

    // If not, wait until the data is available, then start rendering
    else {
      $webcam.addEventListener('canplay', renderVideo, { once: true });
    }
  }


  // Component
  return (
    <Box width="100%" height="100%" backgroundColor="black">
      <canvas ref={ canvasRef } style={{"object-fit": "contain", "width": "100%", "height": "100%"}}></canvas>
    </Box>
  )
}
