import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Typography,
  Button,
  Modal,
  Checkbox,
  FormControlLabel,
  Alert
} from "@mui/material";

const { fetchUser } = require('../api/user-service');

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '80%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: {
    xs: '80%',
    sm: '60%',
    md: 400,
  }
};

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState('');
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser().then(data => {
      if (data.username) {
        setIsLoggedIn(true);
        return navigate('/profile');
      }
    },
    error => {
      setIsLoggedIn(false);
    });
  }, []);
  
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${SERVER_URL}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ username, password }),
        });
      if (!res.ok) {
        if (res.status === 401) {
          setError('Invalid username or password');
          setUsername('');
          setPassword('');
        }
      } else {
        setPassword('');
        const data = await res.json();
        if (data.tos_accepted === false) {
          handleOpen();
        } else {
          return navigate('/profile');
        }
      }
    } catch(err) {
      setError('Error logging in. Please try again later.');
      console.error('Error logging in:', err);
    }
  };

  const handleLoginContinue = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${SERVER_URL}/user/tos`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
      })
      handleClose();
      if (res.ok) {
        return navigate('/profile');
      }
    } catch(err) {
      throw new Error('Error updating user:', err);
    }
   }



  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {error ? <Alert severity="error" onClose={() => setError('')}>{error}</Alert> : ''}
      <Typography component="h1" variant="h5">Sign In</Typography>
      <Box component="form" onSubmit={loginSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextField 
          required
          margin="normal"
          id="username"
          name="username"
          label="Username" 
          variant="outlined"
          autoCapitalize="none"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{backgroundColor: 'white'}}
        />
        <TextField 
          required
          margin="normal"
          id="password"
          name="password"
          label="Password"
          type="password"
          variant="outlined"
          autoCapitalize="none"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{backgroundColor: 'white'}}

        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
      </Box>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Terms of Service
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Typography sx={{ mt: 2 }}>
              These instructional materials have been prepared to guide Fontan Choir participants in at-home voice and breathwork practice.  All of the instructional content was developed by voice teaching professionals in consultation with medical staff at Lurie Children’s Hospital. The content does not necessarily reflect the views or policies of Northwestern University or Lurie Children’s Hospital.
            </Typography>
            <Typography sx={{ mt: 2 }}>
            If, at any time you feel pain or discomfort, stop the video immediately and contact your physician. Caregivers are advised to monitor patients’ activity during the tutorial to ensure their safety. 
            </Typography>
            <Typography sx={{ mt: 2 }}>
            When participants engage with the videos, the platform will automatically create a video recording of their activity. Please ensure that there is no identifiable information in the background of the video before starting the program.  
            </Typography>
            <Typography sx={{ mt: 2 }}>
            Northwestern University does not make any representation or warranty about the accuracy, applicability, fitness or completeness of any material contained in this publication. These training materials are not a substitute for medical advice or treatment and users should seek medical treatment relevant to their particular circumstances. Neither the University or its authors shall be liable for any and all liability to any party for any direct, indirect, implied, punitive, special, incidental or other consequential damages arising directly or indirectly from any use of the training material, which is provided as is, without warranties as well as any action taken or reliance made by you on any information contained in this material. 
            </Typography>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox name="tos"
                checked={isChecked} 
                onChange={handleCheckboxChange}
              />
            }
            label="I accept and agree to the Terms of Service"
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isChecked}
            onClick={handleLoginContinue}
          > Continue </Button>
        </Box>
      </Modal>
     
    </Box>
  );
}

export default Login;