import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import * as Sentry from "@sentry/react";

import Footer    from './components/footer/Footer';
import Login     from './components/Login';
import Dashboard from './components/Dashboard';
import VideoPage from './components/Video';

import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import StudentDashboard from './components/StudentDashboard';
import AdminDashboard from './components/AdminDashboard';
import StudentActivity from './components/StudentActivity';



/**
 * Initialize Sentry for error tracing
 */

Sentry.init({
  dsn: "https://2ae8862bcb414c52bd2b6549eac19305@o353488.ingest.us.sentry.io/4508179161022464",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation, useNavigationType, createRoutesFromChildren, matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracing environment from env var
  environment: process.env.REACT_APP_ENV || null,

  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/dev\.fontanpractice\.northwestern\.edu/,
    /^https:\/\/fontanpractice\.northwestern\.edu/,
  ],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



/**
 * Initialize React
 */


// Create React root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Set up router
const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/profile",
    element: <Dashboard />,
  },
  {
    path: "/student",
    element: <StudentDashboard />,
  },
  {
    path: "/admin/:studentId",
    element: <StudentActivity />,
  },
  {
    path: "/admin",
    element: <AdminDashboard />,
  },
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "/video/:id",
    element: <VideoPage />,
  },
]);

// Render the React app
root.render(
  <React.StrictMode>
    <div style={{"flex-grow": "1"}}>
      <RouterProvider router={router} />
    </div>
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
