// React
import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

// MUI
import { 
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';



export default function ListStudentsTable({ students, selected, setSelected }) {

    // Component
    return (
        <Table className="student-table" stickyHeader sx={{borderCollapse: "separate"}}>

            {/* Header Row */}
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Student ID</TableCell>
                    <TableCell>Use Webcam?</TableCell>
                </TableRow>
            </TableHead>

            {/* Body */}
            <TableBody>
                {(students || []).map((student) => (
                    <TableRow key={student.id} sx={{
                        // Make selected row sticky
                        position: student.id === selected?.id ? "sticky" : "revert",
                        top:      student.id === selected?.id ? "57.5px" : "revert",
                        bottom:   student.id === selected?.id ? 0        : "revert",
                        zIndex:   student.id === selected?.id ? 9        : "revert",
                        // Highlight selected row
                        backgroundColor: student.id === selected?.id ? "#ceebfd" : "white",
                    }}>

                        {/* Selection Indicator */}
                        <TableCell sx={{paddingRight: 0}}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                { student.id === selected?.id ? <RemoveRedEyeIcon sx={{color: "#4447ad"}} /> : null }
                            </Box>
                        </TableCell>

                        {/* Student Username */}
                        <TableCell>
                            <Button className="student-link" onClick={() => setSelected(student)}>
                                <AccountCircleIcon sx={{marginRight: 1}}/>
                                <Typography fontWeight={student.id === selected?.id ? "bolder" : "unset"}>
                                    {student.username}
                                </Typography>
                            </Button>
                        </TableCell>

                        {/* Webcam Permissions */}
                        <TableCell>
                            { student.use_webcam ? "Yes" : "No" }
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
