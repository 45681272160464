const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const fetchVideos = async () => {
    try {
        const resp = await fetch(`${SERVER_URL}/courses`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
        if (resp.ok) {
            const data = await resp.json();
            return data;
        } else {
            throw new Error(`Error fetching videos: ${resp.status}`);

        }
    } catch(err) {
        throw err;
    }
}

// TODO: Implement this server-side
const fetchVideo = async ( videoID ) => {

    // Fetch list of all videos
    const videos = await fetchVideos();
    if (!videos) {
        throw new Error("Could not retrieve videos.")
    }

    // Loop through checking unique IDs
    for (let video of videos) {
        if (video.id === videoID) {
            return video
        }
    }

    // If we made it this far, the ID wasn't found
    throw new Error("No video found.")
}

module.exports = { fetchVideos, fetchVideo };