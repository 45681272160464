import React from "react";
import { Box, Typography, Button } from "@mui/material";

const Error = ({ message }) => {

    return (
        <Box sx={{textAlign: 'center'}}>
            <Typography variant="h3">Sorry! There was an error.</Typography>
            <Typography variant="h4">{message}</Typography>
            <Button variant="contained" sx={{marginTop: 2}} onClick={() => window.history.back()}>Go back</Button>
        </Box>
    )
};

export default Error;
