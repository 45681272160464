const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const fetchUser = async () => {
    try {
        const resp = await fetch(`${SERVER_URL}/auth/profile`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });
        if (resp.ok) {
            const user = await resp.json();
            return {username: user.username, role: user.role, use_webcam: user.use_webcam};
        } else {
            throw new Error(`Error fetching user: ${resp.status}`);
        }
    } catch (err) {
        throw err;
    }
};

const fetchStudents = async () => {
    try {
        const resp = await fetch(`${SERVER_URL}/students`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });
        if (resp.ok) {
            const students = await resp.json();
            return students;
        } else {
            throw new Error(`Error fetching students: ${resp.status}`);
        }
    } catch (err) {
        throw err;
    }
};

const userRoles = Object.freeze({
    ADMIN: 'admin',
    STUDENT: 'student',
  })

module.exports = { fetchUser, fetchStudents, userRoles };