// --------------------------------------------------
//   Helper Functions
// --------------------------------------------------

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Request a WakeLock for the screen, returning `null` if the request fails.
 */
async function getOptionalWakeLock() {

  // Request a WakeLock for the screen and return it
  try {
    return await navigator.wakeLock.request("screen");
  }

  // If the WakeLock request fails, skip it
  catch (err) {
    console.log(`Failed to obtain WakeLock: ${err.name}, ${err.message}`);
    return null;
  }
}


/**
* Request an AWS signed URL from the API.
*/
async function getUploadURL( contentType ) {

  // TODO: Fix later (new URL strips trailing API environment specifier)
  // // Construct the route in the API
  // const apiRoute = new URL("/upload-url", process.env.REACT_APP_SERVER_URL);

  // // Add content type, if specified
  // if (contentType) {
  //   apiRoute.searchParams.append("contentType", contentType);
  // }

  // Fetch signed URL from API
  try {
    const apiResponse = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/upload-url`,
      {
        credentials: "include",
      }
    )

    if (!apiResponse.ok) {
      throw new Error('Unable to construct upload URL');
    }

    return await apiResponse.json();
  }

  // Intercept error, print where it came from, then propagate
  catch (e) {
    console.error('Unable to construct upload URL');
    throw e;
  }
}



// --------------------------------------------------
//   Main Logic
// --------------------------------------------------


/**
 * Request an AWS signed URL and upload a video blob to that URL.
 *
 * Args:
 *   - blob: The raw blob to upload to AWS
 *   - onProgress: An optional callback to run with progress updates.
 */
export async function uploadRecording(blob, onProgress) {
  console.log("Uploading...")

  // Promise that resolves when upload is complete, or rejects if upload is interrupted
  return new Promise( async (resolve, reject) => {

    // Request a WakeLock for the screen
    const wakeLock = await getOptionalWakeLock();

    // Fetch signed URL from API
    let url;
    try {
      let response = await getUploadURL( blob.type );
      url = response.url;
    }
    catch (e) {
      reject(e)
    }

    // Initialize new XHR Request to stream recording blob to AWS
    const xhrRequest = new XMLHttpRequest();
    xhrRequest.open("PUT", url);

    // Set headers
    if (blob.type) {
      xhrRequest.setRequestHeader( "Content-Type", blob.type );
    }


    // Create error handler
    const handleErr = async (event) => {

      // Release the WakeLock
      if (wakeLock) await wakeLock.release();

      // Raise event to caller
      reject(event);
    }

    // Attach error handler to upload failure conditions
    xhrRequest.addEventListener("abort", handleErr);
    xhrRequest.addEventListener("error", handleErr);


    // Attach progress listener, if provided
    if (onProgress) {
      xhrRequest.upload.addEventListener("progress", ({ loaded, total }) => onProgress(loaded, total));
    }


    // Attach success handler
    xhrRequest.addEventListener("load", async (event) => {
      console.log("Uploaded successfully!");

      // Release the WakeLock
      if (wakeLock) await wakeLock.release();

      resolve(event);
    });


    // Begin the upload
    xhrRequest.send( blob );
  });
}
