// React
import { useState, useEffect } from 'react';

// Sentry
import * as Sentry from "@sentry/react";



export const ErrorState = Object.freeze({
  NO_WEBCAM:         'NO_WEBCAM',
  UPLOAD_FAILED:     'UPLOAD_FAILED',
  NO_PRACTICE_AUDIO: 'NO_PRACTICE_AUDIO',
  ACTIVITY_LOOKUP_FAILED: 'ACTIVITY_LOOKUP_FAILED',
});


export function useTrackedError() {

  // Create new React state to track error
  const [ errorObject, setErrorObject ] = useState({ state: null, object: null, message: [] });


  // When error object is updated, track it with Sentry
  useEffect(() => {
    if ( errorObject?.state ) {
      trackError( errorObject.state, errorObject.object );
    }
  }, [ errorObject ]);


  // Set a new error state
  function set( errorState, errorObject, errorMessage = [] ) {
    setErrorObject({
      state:   errorState,
      object:  errorObject,
      message: errorMessage,
    })
  }


  // Clear the existing error state
  function clear() {
    setErrorObject({ state: null, object: null, message: [] });
  }


  // Return the React state object and the two modification functions
  return [ errorObject, { set, clear } ]
}


export function trackError(errorState, errorObject) {

  // Wrap the custom ErrorState and source error into a single JS Error object
  const wrappedError = new Error( errorState, {cause: errorObject} )

  // Track with Sentry
  Sentry.captureException(wrappedError);
}
