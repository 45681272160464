// --------------------------------------------------
//   Imports
// --------------------------------------------------

// Material UI
import {
  Box,
  Link,
  Typography,
} from "@mui/material";



// --------------------------------------------------
//   Component
// --------------------------------------------------

export default function Footer() {

  // Links
  const footerLinks = [
    {
      text: "Disclaimer",
      href: "https://www.northwestern.edu/disclaimer.html",
    },
    {
      text: "Privacy Statement",
      href: "https://www.northwestern.edu/privacy/",
    },
    {
      text: "University Policies",
      href: "https://policies.northwestern.edu/",
    },
    {
      text: "Report an Accessibility Issue",
      href: "https://www.northwestern.edu/accessibility/about/report-accessibility-issue.html",
    },
    {
      text: "Support / Contact Us",
      href: "mailto:fontan-support@northwestern.edu",
    },
  ];

  return (
    <Box component={"footer"}
      className="app-footer"
      flexWrap={{xs: "wrap", md: "nowrap"}}
      columnGap={{xs: "0.75em", sm: "1em"}}
    >

      {/* Copyright */}
      <Box
        flex={{xs: "1 1 100%", md: "1"}}
        textAlign={{xs: "center", md: "left"}}
        mt={{xs: 0, md: "4px"}}
      >
        <Typography
          variant="body2" color="text.secondary"
          fontSize={{xs: "0.75rem", sm: "0.875rem"}}
        >© 2024 FONTAN Practices</Typography>
      </Box>

      {/* Links */}
      {
        footerLinks.map(({text, href}, idx) => (
          <Link key={idx} href={href} target="_blank" fontSize={{ xs: "0.8em", sm: "0.9em" }}>{ text }</Link>
        ))
      }
    </Box>
  )
}