// React
import React, {useState, useEffect} from "react";

// MUI
import {
    Box,
    CircularProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Typography,
} from '@mui/material';

// API
import { fetchStudentActivities } from '../../api/activity-service';
import { ErrorState, useTrackedError } from "../../scripts/errorHandling";



// Table Columns
const columns = [
    { id: 'activityType', label: 'Activity Type', align: 'left', minWidth: 100 },
    { id: 'activityDate', label: 'Activity Date', align: 'left', minWidth: 100 },
    { id: 'videoName',    label: 'Video',         align: 'left', minWidth: 70 },
    { id: 'recordings',   label: 'Recordings',    align: 'left', minWidth: 70 },
];


// Component
// Accepts student ID to look up OR list of activities to render
// If both given, goes to student ID over activities list
export default function StudentActivityTable({ studentID = null, activity = null, studentName = "", initialPage = 0, initialRowsPerPage = 25 }) {

    const [ studentActivity, setStudentActivity ] = useState([])

    const [ errorState, manageErrorState ] = useTrackedError();
    const [ isLoading, setIsLoading ] = useState(false);

    // Table params
    const [page, setPage] = useState(initialPage);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };

    const convertDate = (date) => {
        let d = new Date(date);
        return d.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    };


    // Look up student activities any time student ID changes
    useEffect(() => {
        if (studentID === null || studentID === undefined) {
            return;
        }

        // Reset state
        setIsLoading(true);
        manageErrorState.clear();

        // Get student's activities, sorted by date in descending order
        fetchStudentActivities(studentID).then(
            data => {
                data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                setStudentActivity(data);
                setIsLoading(false);
            },
            error => {
                setIsLoading(false);
                manageErrorState.set(ErrorState.ACTIVITY_LOOKUP_FAILED, error);
            }
        );
    }, [studentID]);


    // Check for conflicting props
    useEffect(() => {
        if (!(studentID === null || studentID === undefined) && activity) {
            console.warn("Both a Student ID and an activities list have been passed to StudentActivityTable. Rendering the student ID.")
        }
    }, [studentID, activity])

    // Render from Student ID over activity list prop, if both given
    const activitiesToRender = (studentActivity ? studentActivity : activity)


    // Component(s)
    return (<>

        {/* Table */}
        <Table stickyHeader aria-label="sticky table" sx={{minHeight: "90%"}}>

            {/* Header */}
            <TableHead>
                <TableRow sx={{borderBottom: 2}}>
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                    >
                        {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>

            {/* Body */}
            <TableBody>
                {
                isLoading
                    ? (
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Box display="flex" justifyContent="center" alignItems="center" width="100%" paddingY={4}>
                                    <CircularProgress />
                                </Box>
                            </TableCell>
                        </TableRow>
                    )
                : errorState?.state
                    ? (
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Typography sx={{textAlign: 'center'}}>There was a problem loading activities for this student. Please try again later.</Typography>
                            </TableCell>
                        </TableRow>
                    )
                : (!activitiesToRender?.length)
                    ? (
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Typography sx={{textAlign: 'center'}}>No activities found</Typography>
                            </TableCell>
                        </TableRow>
                    )
                    : activitiesToRender
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((a, i) => {
                            return (
                                <TableRow hover key={i}>
                                    <TableCell align="left">{a.activity_type}</TableCell>
                                    <TableCell align="left">{convertDate(a.created_at)}</TableCell>
                                    <TableCell align="left">{a.video_name}</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            )
                        })
                }
            </TableBody>

        </Table>

        {/* Pagination */}
        <Stack
            direction={{xs: "column", md: "row"}}
            sx={{ position: "sticky", bottom: 0, backgroundColor: "white", borderTop: "1.5px solid lightgrey", width: "100%" }}
        >
            { !studentName ? null :
                <Box display="flex" alignItems="center" paddingX={2} paddingY={1}>
                    <Typography>
                        Activities for <span style={{fontWeight: "bold", color: "#4447ad"}}>{studentName}</span>
                    </Typography>
                </Box>
            }
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={activitiesToRender.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{flex: 1}}
            />
        </Stack>
    </>)
};
