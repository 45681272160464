// --------------------------------------------------
//   Imports
// --------------------------------------------------

// React
import { useState, useEffect, useRef } from 'react';

// Material UI
import {
  Box,
  IconButton,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

// Custom Components
import SelectDeviceDialog from '../mediaDevices/SelectDeviceDialog';
import VideoCanvas from '../mediaDevices/VideoCanvas';



// --------------------------------------------------
//   Component
// --------------------------------------------------

let contentType = null;

export default function WebcamVideo({ videoRef, on = false, onStart, createVideo = true }) {


  // Track current active devices
  const [ videoDevice, setVideoDevice ] = useState(null);
  const [ audioDevice, setAudioDevice ] = useState(null);

  // Track settings menu
  const [ webcamDialogOpen, setWebcamDialogOpen ] = useState(false);

  const openWebcamSettings = () => {
    setWebcamDialogOpen(true);
  };

  const closeWebcamSettings = () => {
    setWebcamDialogOpen(false);
  };


  useEffect(() => {
    if (on) {
      startWebcam({})
        .then(() => onStart())
        .catch((err) => onStart(err))
    }
  }, [on])


  /**
   * Request a new media stream, optionally specifying the audio and video device IDs to use.
   * Stores the source device IDs in the `audioDevice` and `videoDevice` state variables.
   */
  async function startWebcam({ videoDeviceID = null, audioDeviceID = null }) {

    // Get the child elements by reference
    const $webcam = videoRef.current;

    // If explicit device IDs given, use those, otherwise fallback to basic requirements
    const constraints = {
      audio: audioDeviceID ? { deviceId: audioDeviceID } : {
        sampleRate: 44000,
        echoCancellation: false,
        noiseSuppression: false,
      },
      video: videoDeviceID ? { deviceId: videoDeviceID } : {
        facingMode: "user",
      },
    }

    // Attach camera to webcam stream video element
    const webcamStream = await navigator.mediaDevices.getUserMedia(constraints);
    $webcam.srcObject = webcamStream;

    // Extract the devices used to make this stream and update internal state
    // NOTE: This implicitly assumes there will only be one track of each type (audio and video)
    webcamStream.getTracks().forEach((track) => {
      if (track.kind === "audio") {
        setAudioDevice( track.getSettings().deviceId )
      }
      else if (track.kind === "video") {
        setVideoDevice( track.getSettings().deviceId )
      }
    })

    // Start playing the video stream on the <video> element
    try {
      await $webcam.play();
    }

    // Ignore abort errors caused by multiple play calls
    catch (e) {
      console.log("Caught: ", e)
      if (e.name !== 'AbortError') {
        throw e;
      }
    }
  }


  /**
   * Explicitly set (or update) the device ID for the audio source,
   * and re-request a media stream using the specified device(s).
   */
  function changeAudioDevice( audioDeviceID ) {
    setAudioDevice(audioDeviceID);
    startWebcam({ videoDeviceID: videoDevice, audioDeviceID })
  }

  /**
   * Explicitly set (or update) the device ID for the video source,
   * and re-request a media stream using the specified device(s).
   */
  function changeVideoDevice( videoDeviceID ) {
    setVideoDevice(videoDeviceID);
    startWebcam({ videoDeviceID, audioDeviceID: audioDevice })
  }


  // Update the global content type to a supported type,
  // or throw an error if none of them are supported
  // contentType = getFirstSupportedType([ "video/mp4", "video/webm" ]);
  contentType = "video/mp4";


  // Component
  return (
    <Box width="100%" backgroundColor="black">

      { !createVideo ? null :
          <video
            ref         = { videoRef }
            style       = {{"zIndex": "-999", "display": "none", "position": "absolute" }}
            autoPlay    = {true}
            muted       = {true}
            playsInline = {true}
            controls    = {true}
          ></video>
      }

      <VideoCanvas videoRef={ videoRef } />

      <IconButton
        size="large"
        sx={{
          position: "absolute", top:  "6px", left: "6px",
          padding: "0",
          display: on ? "block" : "none",
        }}
        onClick={openWebcamSettings}
      >
        <SettingsIcon
          fontSize="large"
          sx={{ color: "white", stroke: "black", 'stroke-width': "1.5px" }}
        />
      </IconButton>

      <SelectDeviceDialog
        open           = { webcamDialogOpen }
        onClose        = { closeWebcamSettings }
        videoRef       = { videoRef }
        videoDevice    = { videoDevice }
        audioDevice    = { audioDevice }
        setVideoDevice = { changeVideoDevice }
        setAudioDevice = { changeAudioDevice }
      />
    </Box>
  )
}
