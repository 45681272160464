const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const fetchCompletedVideos = async () => {
    try {
        const resp = await fetch(`${SERVER_URL}/completed-videos`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
        if (resp.status === 200) {
            const data = await resp.json();
            // return an array of video IDs
            return data.map((activity) => activity.video_id);
        } 
    } catch(err) {
        throw new Error('Error fetching videos:', err);
    }
}

const createActivity = async (activityType, videoName = null) => {
    try {
        const res = await fetch(`${SERVER_URL}/activity/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ activityType, videoName }),
        })
        return res.json();
    } catch(err) {
        throw new Error('Error creating activity:', err);
    }
}

const fetchStudentActivities = async (userID) => {
    try {
        const resp = await fetch(`${SERVER_URL}/student/${userID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
        if (resp.ok) {
            return resp.json();
        } else {
            throw new Error(`Error fetching student activities: ${resp.status}`);

        }
    } catch(err) {
        throw err;
    }
}

module.exports = { fetchCompletedVideos, createActivity, fetchStudentActivities };