// --------------------------------------------------
//   Imports
// --------------------------------------------------

// React
import { useState, useEffect, useRef } from 'react';

// Material UI
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



// --------------------------------------------------
//   Component
// --------------------------------------------------

export default function DeviceList({ devices, onSelect, selected = null, showIDs = false, listStyles = null }) {

  // const [selected, setSelected] = useState(null);

  return (
    <List sx={listStyles}>
      {(devices || []).map((device, idx) => (
        <ListItem disableGutters key={idx}>
          <ListItemButton onClick={() => onSelect(device.deviceId)}>
            <ListItemAvatar>
              {
                device.deviceId === selected ? (<Avatar sx={{ bgcolor: "#4447ad" }}><CheckIcon /></Avatar>) : null
              }
            </ListItemAvatar>
            <ListItemText
              primary={device.label}
              secondary={showIDs ? device.deviceId : null}
              secondaryTypographyProps={{
                variant: "caption",
                sx: { "overflow-wrap": "anywhere" }
              }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
