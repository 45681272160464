import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Box } from "@mui/material";

// API
import { fetchUser, userRoles } from "../api/user-service";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const btnStyle = {
    mr: 2, 
    mb: 1, 
    fontSize: {
        xs: 7, 
        sm: 10, 
        md: 15
    }
}
const Navigation = () => {

    const [userRole, setUserRole] = useState({});
    const location = useLocation();

    useEffect(() => {
        // Get user
        fetchUser().then(user => setUserRole(user.role));
    })

    const handleLogout = async () => {
        try {
            const res = await fetch(`${SERVER_URL}/auth/logout`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });
            if (res.status === 200) {
                window.location.href = '/login';
            }
        } catch(err) {
            console.error('Error logging out:', err);
        }
    }
    
    return (
        <Box sx={{display: 'flex'}}>
            {userRole === userRoles.ADMIN ? (
                <Box>
                    <Button variant={location.pathname === '/admin' ? 'contained' : 'outlined'} href="/admin" sx={btnStyle}>Admin Dashboard</Button>
                    <Button variant={location.pathname === '/student' ? 'contained' : 'outlined'} href="/student" sx={btnStyle}>Videos</Button>
                </Box>

            ) : ''}
            <Button variant="outlined" onClick={handleLogout} sx={btnStyle}>Sign Out</Button>
        </Box>
        
    )
}

export default Navigation;