// --------------------------------------------------
//   Imports
// --------------------------------------------------

// React
import { useState, useEffect, useRef, Component } from 'react';

// Material UI
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

// Custom script(s)
import { ErrorState, useTrackedError } from '../../scripts/errorHandling';



// --------------------------------------------------
//   Item Component
// --------------------------------------------------

class RecordingListThumbnail extends Component {

  /**
   * Does not update (so video doesn't spam refresh as progress updates)
   */
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    return (
      <CardMedia
        component="video"
        src={ URL.createObjectURL( this.props.recording.blob ) }
        alt="video recording"
        controls
      />
    )
  }
}


function RecordingListItem({ recording, upload }) {

  // State object to track progress of any uploads
  const [ progress, setProgress ] = useState(null);

  // Track upload error state
  const [ errorState, manageErrorState ] = useTrackedError();

  // Helper to upload a recording
  const uploadRecording = (( recording ) => {
    upload(
      recording,

      // Update progress
      (p) => { setProgress(p) },

      // Receive & handle error
      (err) => {
        manageErrorState.set(ErrorState.UPLOAD_FAILED, err, [
          "Upload failed.",
          "This recording is backed up on your device; please refresh the page and try again later.",
        ]);
      },
    )
  })

  // Component
  return (
    <Paper sx={{ maxWidth: "100%" }} elevation={3}>
      <Card>
        <RecordingListThumbnail recording={recording} />
        <CardContent sx={{ paddingBottom: 0 }}>
          <Typography gutterBottom as="h4" variant="body" component="div">
            { recording.title }
          </Typography>
        </CardContent>

        {
          progress === null
            ? (
              <CardActions>
                <Button
                  size="large" color="primary" fullWidth
                  onClick={ () => uploadRecording( recording ) }
                >
                  Upload
                </Button>
              </CardActions>
            )
            : (
              <>
                <CardContent>
                  <Typography as="p" color={ errorState?.state ? "error" : "inherit" }>
                    {
                      errorState?.state
                        ? errorState.message.join(" ")
                        : (progress < 100)
                          ? "Uploading. DO NOT leave this page."
                          : "Uploaded! You may now leave this page."
                    }
                  </Typography>
                </CardContent>
                <LinearProgress
                  variant="determinate"
                  value={ progress }
                  color={ errorState?.state ? "error" : (progress < 100 ? "primary" : "success")}
                  style={{"height": "32px"}}
                  sx={{
                    transition: "none",
                  }}
                />
              </>
            )
        }
      </Card>
    </Paper>
  );
}



// --------------------------------------------------
//   Component
// --------------------------------------------------

class RecordingList extends Component {

  /**
   * Main render function
   */
  render() {
    return (
      <Stack direction="column" alignItems="center" gap={2}>
        {
          this.props.recordings.slice().reverse().map((recording) => {
            return (
              <RecordingListItem key={recording.id}
                recording = { recording }
                upload    = { this.props.upload }
              />
            )
          })
        }
      </Stack>
    )
  }
}

export default RecordingList;
