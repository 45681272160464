// --------------------------------------------------
//   Imports
// --------------------------------------------------

// React
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

// Material UI
import {
    Box,
    Button,
    CircularProgress,
    Drawer,
    Fab,
    Grid2 as Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";

// Local
import PracticeVideo from "./video_page/PracticeVideo";
import WebcamVideo   from "./video_page/WebcamVideo";
import VideoControls from "./video_page/VideoControls";
import RecordingList from './video_page/RecordingList.js';

// Scripts: Back up recordings locally
import {
  getStoredRecordings,
  storeRecording,
  clearRecording,
} from "../scripts/backupRecordings.js";

// Scripts: Upload recording to datastore
import {
  uploadRecording,
} from "../scripts/uploading.js"

// Scripts: Tracking errors
import { ErrorState, useTrackedError, trackError } from '../scripts/errorHandling.js';

// Scripts: Fetching from API
import { fetchUser }  from "../api/user-service";
import { fetchVideo } from '../api/course-service.js';

// API
import { createActivity } from '../api/activity-service';

const activityTypes = Object.freeze({
  USER_START_RECORDING:  'USER_START_RECORDING',
  USER_PAUSED_RECORDING: 'USER_PAUSED_RECORDING',
})

// --------------------------------------------------
//   Constants
// --------------------------------------------------


// customize here for each video
const AUDIO_VOLUME_WEBCAM = 1.0; // 0..1
const AUDIO_VOLUME_VIDEO = 0.2; // 0..1
// end customization

let contentType = null;



// --------------------------------------------------
//   Helper Functions
// --------------------------------------------------


/**
 * Safely get a capture stream.
 */
function getCaptureStream( $element ) {

  // By default, the function is captureStream
  try {
    return $element.captureStream();
  }

  // The captureStream method doesn't exist on video elements in Safari,
  // and the Firefox-specific mozCaptureStream gives iffy results,
  // but we can forward the video content to a canvas and use that instead
  catch (e) {

    // Create canvas to draw combined video
    const videoCanvas = document.createElement('canvas');
    const videoCtx = videoCanvas.getContext('2d');

    const videoWidth  = $element.videoWidth;
    const videoHeight = $element.videoHeight;

    // Adjust canvas size to match webcam video dimensions
    videoCanvas.width  = videoWidth;
    videoCanvas.height = videoHeight;

    // Start animation loop
    function draw() {
      videoCtx.drawImage( $element, 0, 0, videoWidth, videoHeight);
      requestAnimationFrame(draw);
    }
    draw();

    // Create a capture stream from the canvas
    return videoCanvas.captureStream();
  }
}


/**
 * Create a MediaRecorder object that combines the two sources into one.
 */
function createMediaRecorder( $webcam, $video, useWebcam = true ) {

  // If not using webcam, just return the video stream
  if (!useWebcam) {
    return new MediaRecorder( getCaptureStream($video) );
  }

  // Get the webcam stream from the canvas element
  const webcamStream = $webcam.srcObject;
  if (!webcamStream) {
    console.warn("screen stream is not ready. Try again");
    return;
  }


  // Create objects to record combined media

  // Create combined audio context
  const audioCtx = new AudioContext();

  // Create canvas to draw combined video
  const videoCanvas = document.createElement('canvas');
  const videoCtx = videoCanvas.getContext('2d');

  // Debug styling to view canvas in real time
  // videoCanvas.style.position = "fixed"
  // videoCanvas.style.top  = "0px"
  // videoCanvas.style.left = "0px"
  // videoCanvas.style.zIndex = "99"
  // document.body.append( videoCanvas )


  // Combine Audio

  // Audio Source 1: Webcam Recording
  const source1 = audioCtx.createMediaStreamSource(webcamStream);
  const gain1 = audioCtx.createGain();
  gain1.gain.value = AUDIO_VOLUME_WEBCAM;
  source1.connect(gain1);

  // Audio Source 2: Practice Video
  let gain2;
  try {
    const source2 = audioCtx.createMediaStreamSource( getCaptureStream($video) );
    const gain2 = audioCtx.createGain();
    gain2.gain.value = AUDIO_VOLUME_VIDEO;
    source2.connect(gain2);
  }

  // Track with Sentry
  catch (e) {
    console.error("Couldn't get audio for practice video: ", e);
    trackError( ErrorState.NO_PRACTICE_AUDIO, e )
  }

  // Combined Audio
  const dest = audioCtx.createMediaStreamDestination();
  gain1.connect(dest);
  if (gain2) gain2.connect(dest);


  // Combine video

  // Adjust canvas size to match webcam video dimensions
  videoCanvas.width  = $webcam.videoWidth;
  videoCanvas.height = $webcam.videoHeight;

  // Compute recording video size to fit in corner of combined video
  const videoAspectRatio = $video.videoWidth / $video.videoHeight;
  const videoWidth  = videoCanvas.width / 3;
  const videoHeight = videoWidth / videoAspectRatio;

  // Start animation loop
  // Draw webcam video across the whole canvas, and recording in top left corner
  function draw() {
    videoCtx.drawImage( $webcam, 0, 0, videoCanvas.width, videoCanvas.height);
    videoCtx.drawImage( $video,  0, 0, videoWidth, videoHeight);
    requestAnimationFrame(draw);
  }
  draw();


  // Combine everything

  // Create output stream: audio + video from both sources
  const outputStream = new MediaStream();
  outputStream.addTrack(videoCanvas.captureStream().getVideoTracks()[0]);
  outputStream.addTrack(dest.stream.getAudioTracks()[0]);

  // Return a MediaRecorder object recording the outut stream
  return new MediaRecorder(outputStream);
}


/**
 * Record, save, and upload a video from the given MediaRecorder.
 */
function record(mediaRecorder, onStop) {

  // Initialize chunks
  let chunks = [];

  // When media recorder data available, append it to the list of chunks
  mediaRecorder.ondataavailable = event => {
    console.log("media recorder data available");
    if (event.data.size > 0) {
      chunks.push(event.data);
    }
  }

  // When media recorder stops, upload the resulting video
  mediaRecorder.onstop = async () => {
    console.log("media recorder stopped");

    // Convert the recording to a blob
    const blob = new Blob(chunks, { contentType });

    // Generate a title for the recording
    const title = (new Date()).toLocaleString();

    // Back up the recording locally
    let recordingID
    try {
      recordingID = await storeRecording(title, blob);
    } catch (e) {
      console.error('Could not back up locally.');
    }

    // Update the controls
    onStop();
  }

  // Start the media recorder
  mediaRecorder.start();
  console.log("started media recorder");
}



// --------------------------------------------------
//   Component
// --------------------------------------------------

let mediaRecorder = null;

export default function VideoPage() {

  // Confirm login
  const [username, setUsername] = useState('');
  useEffect(() => {
    // Get user
    (async () => {
        try {
          const data = await fetchUser();
          if (!data) {
            window.location.href = '/login';
          }
          setUsername(data);
          setUseWebcam(data.use_webcam);
        } catch(err) {
          console.error(err);
        }
    })();
  }, []);

  // Router parameters
  const params = useParams()

  // Control webcam state
  const [ useWebcam,   setUseWebcam   ] = useState(false);
  const [ webcamOn,    setWebcamOn    ] = useState(false);
  const [ webcamReady, setWebcamReady ] = useState(false);
  const [ skipWebcam,  setSkipWebcam  ] = useState(false);

  const [ recordings, setRecordings ] = useState([]);

  const [ video, setVideo ] = useState(null);
  useEffect(() => {
    fetchVideo( params.id ).then(video => setVideo(video));
  }, [])


  // Error state
  const [ errorState, manageErrorState ] = useTrackedError();


  useEffect(() => {
    refreshRecordingList();
  }, []);


  async function startWebcam() {
    console.log('Start!');
    setWebcamOn(true);
  }

  async function webcamCallback(err) {
    if (!err) {
      setWebcamReady(true);
      return;
    }

    // If webcam start failed, show an error message
    console.error("Unable to start webcam: ", err);
    manageErrorState.set( ErrorState.NO_WEBCAM, err, [
      "Sorry, we couldn't start your webcam.",
      "Please try giving this site permission to use your camera. For best results, please try using Google Chrome.",
      "If you are seeing this error repeatedly, please reach out to us through the \"Support\" link at the bottom of the page.",
      "", "",
      "If you still can't enable your webcam, you may also play the video without recording:",
    ]);
  }
  
  function startRecording( onStop ) {
    console.log('Record!');

    // Create a new media recorder object from the two sources
    mediaRecorder = createMediaRecorder( webcamRef.current, videoRef.current, useWebcam && !skipWebcam );

    // Record
    record( mediaRecorder, () => {
      refreshRecordingList();
      // TODO: Need something like this to reset controls at the end
      // onStop();
    });

    // Start the practice video
    videoRef.current.play();

    // create activity
    createVideoActivity(activityTypes.USER_START_RECORDING, video.name)
  }
  
  function stopRecording() {
    console.log('Stop!');
    videoRef.current.pause();
    mediaRecorder.stop();

    // create activity
    createVideoActivity(activityTypes.USER_PAUSED_RECORDING, video.name)
  }

  async function refreshRecordingList() {
    setRecordings( await getStoredRecordings() );
  }


  async function upload( recording, updateProgress, setError ) {

    // Upload the recording to the datastore
    try {
      await uploadRecording( recording.blob, (loaded, total) => {
        updateProgress(loaded / total * 100)
      } );
    }
    catch (e) {
      console.log("Failed to upload: ", e);
      setError(e);
      return;
    }

    // Delete the local storage copy (the backup), if one exists
    if (recording.id) {
      try {
        await clearRecording( recording.id );
      }
      catch (e) {
        console.log("Recording was uploaded, but could not be removed locally.")
      }
    }
  }

  async function createVideoActivity (type, videoName) {
    try {
      await createActivity(type, videoName);
    } catch(err) {
      console.error(err);
    }
  }


  // Control captions
  let [ useCaptions, setUseCaptions ] = useState(false);
  async function toggleCaptions(useCaptions) {
    setUseCaptions(useCaptions);
  }

  // Control fullscreen state
  const [ isFullscreen, setFullscreen ] = useState(false);
  async function toggleFullscreen( isFullscreen ) {
    setFullscreen( isFullscreen );
  }


  // Create refs for the two video source elements
  const webcamRef = useRef(null);
  const videoRef  = useRef(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };


  // Track footer height
  const [footerHeight, setFooterHeight] = useState('0');
  useEffect(() => {

    // Try getting footer element
    let footer;
    try {
      footer = document.getElementsByClassName('app-footer')[0]
    }
    catch (e) {
      return;
    }

    // On window resize, update footer height
    const handleResize = () => {
      setFooterHeight( `${footer.getBoundingClientRect().height}px` );
    };

    // Initial call
    handleResize()

    // Add handler & remove on component unmount
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  // Wait to confirm login
  if (!username) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
        <CircularProgress></CircularProgress>
      </Box>
    )
  }

  const headerHeight = {
    xs: "120px",
    sm: "60px",
    md: "60px",
  }

  const bodyHeight = {
    xs: `calc(100vh - ${ isFullscreen ? "0px" : footerHeight} - ${ isFullscreen ? "0px" : headerHeight.xs })`,
    sm: `calc(100vh - ${ isFullscreen ? "0px" : footerHeight} - ${ isFullscreen ? "0px" : headerHeight.sm })`,
    md: `calc(100vh - ${ isFullscreen ? "0px" : footerHeight} - ${ isFullscreen ? "0px" : headerHeight.md })`,
  }


  const leftPanelContent = (inSidebar) => (
    <Stack direction="column" height="100%">

      {/* Webcam Video */}
      <Box position="relative">
        <WebcamVideo id="webcam-canvas"
          createVideo = { !inSidebar }
          videoRef = { webcamRef }
          on       = { webcamOn }
          onStart  = { webcamCallback }
        />
        {
          ((useWebcam && webcamOn && webcamReady) || (!inSidebar && errorState?.state)) ? null : (
            <Box
              position="absolute" width="100%" height="100%" top={0} left={0}
              display="flex" alignItems="center" justifyContent="center"
            >
              {
                !useWebcam
                  ? <Typography paddingX={6} textAlign="center" variant="body1" color="primary.light" fontSize="1.2em">
                      Don't worry about the camera this time!
                    </Typography>
                  :
                skipWebcam
                  ? <Typography paddingX={6} textAlign="center" variant="body1" color="error.light" fontSize="1.2em">
                      Camera disabled this time.
                    </Typography>
                  :
                (inSidebar && errorState?.state)
                  ? <Typography paddingX={6} textAlign="center" variant="body1" color="error.light" fontSize="1.2em">
                      There was a problem. Please close to see the full error.
                    </Typography>
                  :
                !webcamOn
                  ? <Button variant="contained" onClick={startWebcam}>Start Camera</Button>
                  : <CircularProgress size="5em" />
              }
            </Box>
          )
        }
      </Box>

      {/* Recordings List */}
      <Typography component="h2" variant="h5" padding={2} align="center">
        My Recordings
      </Typography>
      <Box
        paddingX={4}
        paddingBottom={3}
        flex={1}
        sx={{
          "overflow-x": "hidden",
          "overflow-y": "scroll",
          "scrollbar-gutter": "stable both-edges",
        }}
      >
        <RecordingList recordings={recordings} upload={upload} />
      </Box>

    </Stack>
  )


  return (
    <>
    <Box
      position="fixed" width="100vw" height="100vh" top={0} left={0} backgroundColor="#FAF9F6"
      zIndex={ isFullscreen ? "99" : "unset" }
    >

      {/* Header */}
      <Box
        height={headerHeight}
        width="100%"
        borderBottom="1px solid lightgray"
        display={ isFullscreen ? "none" : "default" }
      >
        <Box
          display="flex" justifyContent="center" alignItems="center"
          useFlexGap columnGap={4} rowGap={0}
          flexWrap={{xs: "wrap", lg: "none"}}
          height="100%"
          paddingX={4}
          position="relative"
        >
          <Link href="/student" order={{xs: 1, sm: "unset"}}>
            Go Back
          </Link>
          <Typography variant="h5" flexGrow={1} order={{xs: 1, sm: "unset"}}>
            { (video && video.name) ? `Watch: Video ${video.name}` : "Watch Video" }
          </Typography>
          <Box height={{xs: "fit-content", sm: "70%"}} width={{xs: "42%", sm: "unset"}} flexShrink={1} order={{xs: 0, sm: "unset"}}>
            <img alt="Bienen logo" src='/images/Horizontal-NW-BIENEN.png' style={{objectFit: "contain", height: "100%", width: "100%"}} />
          </Box>
          <Box height={{xs: "fit-content", sm: "100%"}} width={{xs: "42%", sm: "unset"}} flexShrink={1} order={{xs: 0, sm: "unset"}}>
            <img alt="Lurie logo"  src='/images/Lurie Color Logo.png'     style={{objectFit: "contain", height: "100%", width: "100%"}} />
          </Box>
        </Box>
      </Box>

      {/* Main Content */}
      <Box
        height={bodyHeight}
        borderBottom="1px solid lightgray"
        overflow="hidden"
        display="flex" flexDirection="row"
      >

        {/* Left Panel */}
        <Box
          width="350px"
          height="100%"
          display={{
            xs: "none",
            lg: isFullscreen ? "none" : "unset",
          }}
        >
          { leftPanelContent(false) }
        </Box>
        {/* End of Left Panel */}


        {/* Left Panel Drawer */}
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            '& .MuiDrawer-paper': { maxWidth: "450px" },
          }}
        >
          <Fab
            variant="extended"
            color="primary"
            size="large"
            onClick={toggleDrawer(false)}
            sx={{
              margin: 2,
              padding: 2,
            }}
          >
            Close My Camera & Recordings
          </Fab>
          { leftPanelContent(true) }
        </Drawer>
        {/* End of Left Panel */}


        <Box flex={1} height="100%" display="flex" flexDirection="column" position="relative">

          <Fab
            variant="extended"
            color="primary"
            onClick={toggleDrawer(true)}
            sx={{
              position: "absolute",
              top: "16px",
              left: "16px",
              display: {
                xs: "unset",
                lg: isFullscreen ? "unset" : "none",
              },
            }}
          >
            My Camera & Recordings
          </Fab>

          <Box
            flex={1} minHeight={0}
            backgroundColor="black"
            display="flex" alignItems="center" justifyContent="center"
            position="relative"
          >
          {
            (errorState?.state === null)
              ? (
                <PracticeVideo
                  video    = { video }
                  videoRef = { videoRef }
                  useCaptions = { useCaptions }
                />
              )
              : (
                <Box
                  width="100%"
                  height={{ xs: "100%", md: "unset" }}
                  maxHeight="100%"
                  sx={{
                    overflowY: "scroll",
                  }}
                  backgroundColor="black"
                  display="flex"
                  justifyContent="center"
                  position="relative"
                >
                  <Stack
                    maxWidth="850px"
                    height="fit-content"
                    alignItems="center"
                    justifyContent="center"
                    gap={{xs: "0.75em", md: "1.2em"}}
                    paddingX={8}
                    marginTop={{
                      xs: "calc(48px + 16px + 24px)",
                      md: "24px",
                    }}
                    marginBottom="24px"
                  >
                    { (errorState?.message || []).map((line, idx) => (
                      <Typography key={idx}
                        variant  = { idx === 0 ? "h6" : "body1" }
                        lineHeight = { idx === 0 ? "1.3em" : "1.5em" }
                        fontSize = {{
                          xs: idx === 0 ? "1.4em" : "1em",
                          md: idx === 0 ? "2em" : "1.35em",
                        }}
                        paddingBottom = {{
                          xs: idx === 0 ? "0.5em" : 0,
                          md: idx === 0 ? "1em" : 0,
                        }}
                        color="error.light"
                        textAlign="center"
                      >
                        { line }
                      </Typography>
                    )) }

                    <Button
                      variant="contained" color="error"
                      sx={{marginY: 2}}
                      onClick={() => { setSkipWebcam(true); manageErrorState.clear(); }}
                    >
                      Watch Video Without Recording
                    </Button>
                  </Stack>
                </Box>
              )
          }
          </Box>

          <Box
            display={{ xs: errorState?.state ? "none" : "block", md: "block" }}
          >
            <VideoControls
              canStart   = { !useWebcam || skipWebcam || webcamReady }
              onRecord   = { startRecording }
              onStop     = { stopRecording }
              onCaptions = { toggleCaptions }
              onFullscreen = { toggleFullscreen }
            />
          </Box>
        </Box>

      </Box>
    </Box>
    </>
  )
}
