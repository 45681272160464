// --------------------------------------------------
//   Imports
// --------------------------------------------------

// React
import { useState, useEffect, useRef } from 'react';

// Material UI
import {
    Box,
    Button,
    Stack,
    Typography,
} from "@mui/material";

// Custom Components
import { LargeTooltip } from '../Tooltips';



// --------------------------------------------------
//   Component
// --------------------------------------------------

export default function VideoControls({ canStart, onRecord, onStop, onCaptions, onFullscreen }) {

  // Booleans to track state
  let [ isRecording, setIsRecording ] = useState(false);
  let [ useCaptions, setUseCaptions ] = useState(false);
  let [ isFullscreen, setIsFullscreen ] = useState(false);

  const controlsOnRecord = () => {
    setIsRecording(true);
    // onRecord( () => setIsRecording(false) );
    onRecord( controlsOnStop );
  }

  const controlsOnStop = () => {
    setIsRecording(false);
    onStop();
  }

  const toggleCaptions = async () => {
    await onCaptions( !useCaptions );
    setUseCaptions( !useCaptions );
  }

  const toggleFullscreen = async () => {
    await onFullscreen( !isFullscreen );
    setIsFullscreen( !isFullscreen );
  }

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="center">
      <Stack direction="row"
        useFlexGap rowGap={{xs: 1, sm: 2}} columnGap={1}
        flex={1}
        marginX={2} paddingY={1}
        flexWrap={{ xs: "wrap", sm: "none" }}
        justifyContent="center"
      >

        <LargeTooltip title={ !canStart ? "Please enable the camera first!" : "" } placement="top">
          <div>
            <Button onClick={controlsOnRecord} size="large" disabled={!canStart || isRecording }>
              <Typography fontSize={{ xs: "1em", sm: "1.0em", lg: "1.2em" }}>Start Recording</Typography>
            </Button>
          </div>
        </LargeTooltip>

        <Button onClick={controlsOnStop} size="large" disabled={!canStart || !isRecording}>
          <Typography fontSize={{ xs: "1em", sm: "1.0em", lg: "1.2em" }}>Stop Recording</Typography>
        </Button>

        <Box display={{ xs: "none", md: "unset" }} flex={1}></Box>

        <Button onClick={toggleCaptions}>
          <Typography fontSize={{ xs: "0.85em", md: "1em" }}>
            Turn Captions { useCaptions ? "Off" : "On" }
          </Typography>
        </Button>
        <Button onClick={toggleFullscreen}>
          <Typography fontSize={{ xs: "0.85em", md: "1em" }}>
            { isFullscreen ? "Exit" : "Enter" } Fullscreen
          </Typography>
        </Button>

      </Stack>
    </Box>
  )
}
