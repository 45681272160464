import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import "./AdminDashboard.css";
import Navigation from "./Navigation";
import Error from "./Error";

import { fetchStudents, fetchUser, userRoles } from "../api/user-service";
import { 
    Box, 
    CircularProgress,
    Paper,
    Stack,
    TableContainer,
    Typography,
} from "@mui/material";

// Custom Components
import ListStudentsTable    from "./tables/ListStudentsTable";
import StudentActivityTable from "./tables/StudentActivityTable";



const AdminDashboard = () => {
    const [students, setStudents] = useState([]);
    const [user, setUser] = useState({});
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [ selectedStudent, setSelectedStudent ] = useState(null);

    useEffect(() => {
        // Get students
        fetchStudents()
        .then(students => {
            students.sort((a, b) => a.username.localeCompare(b.username))
            setStudents(students)
            setIsLoading(false);
        },
        error => setError(error.message));

        // Get user
        fetchUser().then(user => {
            setUser(user)
            setIsLoading(false);
        },
        error => {
            setIsLoading(false);
            setError(error.message)
        });
    }, []);

    if (error) {
        return <Error message={error} />
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                <CircularProgress></CircularProgress>
            </Box>
        )
    }

    if (user.role !== userRoles.ADMIN) {
        return (
            <Box>
                <Typography variant="h5">You are not authorized to view this page. Go to <a href="/login">homepage</a>.</Typography>
            </Box>
        )
    }


    return (
        <Box sx={{marginLeft: {xs: 0, md: 4}, paddingBottom: {xs: 4, md: 0}}}>

            {/* Top Message */}
            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                <Typography variant="h5">Hello, {user.username}!</Typography>
                <Navigation />
            </Box>
            <Typography variant="h6">Check out participants activities</Typography>

            <Stack direction={{xs: "column", md: "row"}} gap={4} marginTop={4} maxHeight={{xs: "none", md: "65vh"}}>

                {/* Student Table */}
                {!students.length ? <Typography>No students found.</Typography> : (
                    <TableContainer component={Paper} elevation={4} sx={{
                        maxHeight: "min(100%, fit-content)",
                        maxWidth: {
                            xs: 'unset',
                            md: '25rem',
                        },
                    }}>
                        <ListStudentsTable students={students} selected={selectedStudent} setSelected={setSelectedStudent} />
                    </TableContainer>
                )}

                <TableContainer component={Paper} elevation={4} sx={{
                    maxHeight: {xs: '80vh', md: '100%'},
                }}>
                    {
                        selectedStudent
                            ? <StudentActivityTable studentID={selectedStudent?.id} studentName={selectedStudent?.username} />
                            : (
                                <Box display="flex" justifyContent="center" alignItems="center" height="100%" padding={3} textAlign="center" boxSizing="border-box">
                                    <Typography>Choose a student to view their recorded activity.</Typography>
                                </Box>
                            )
                    }
                </TableContainer>
            </Stack>
        </Box>
    );
};

export default AdminDashboard;