import React, {useState, useEffect} from "react";
import { useParams, useLocation } from 'react-router-dom';
import {
    Paper,
    TableContainer,
    Typography,
    Box,
    Link,
} from '@mui/material';

//  Components
import Logout from "./Navigation";
import StudentActivityTable from "./tables/StudentActivityTable";



const StudentActivity = () => {
    const params = useParams();
    const location = useLocation();

    const {studentUsername} = location.state || {};

    // Component
    return (
        <div>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Link href={'/admin'}>Back</Link>
                <Logout />
            </Box>
            <Typography variant="h6" sx={{textAlign: 'center', marginBottom: 2}}>Activities for {studentUsername}</Typography>

            <TableContainer component={Paper} elevation={4} sx={{maxHeight: '60vh', margin: 'auto', width: '80%'}}>
                <StudentActivityTable studentID={params.studentId} />
            </TableContainer>
        </div>
    )
};

export default StudentActivity;